
.rounded-button {
    border-radius: 50% !important;
    min-width: 0px !important;
    width: 50px !important;
    height: 50px !important;
}

.rounded-button .MuiButton-label {
    position: absolute;
    left: 5px;
}

.square-button {
    background-color: #282828 !important;
    color: #fff !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.MuiDialog-paperWidthSm {
    min-width: 500px;
}

.large-icon {
    width: 35% !important;
    height: 35% !important;
}

.table-filters {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    margin-bottom: 10px;
}

.table-filter .MuiInput-root {
    width: 100%;
}

.type-chip-Pulled {
    background-color: #a4c2f4 !important;
}

.type-chip-Performed {
    background-color: #b6d7a8 !important;
}
